import React from 'react';

import * as styles from './about-us.module.scss';

export const paragraphsData = [
  <>
    Finding exceptionally skilled software engineers able to act on complex custom software solutions is a difficult
    task that businesses have to face daily. According to a McKinsey survey, almost{' '}
    <span className={styles.boldText}>87% of companies globally experience a skills gap</span>. The talent pool is
    shallow, and finding the right professionals is time-consuming and slows down innovations.
  </>,
  <>
    This is why companies are looking to outsource software development to help them avoid the{' '}
    <span className={styles.boldText}>harmful effects of in-house talent shortages</span>. However, finding a software
    development outsourcing company with the necessary expertise and dedicated teams to deliver expected results is a
    hard challenge to crack.
  </>,
  <>
    At CodiLime, we have been partnering with industry leaders since 2011 and deliver{' '}
    <span className={styles.boldText}>exceptional custom software development services</span>, including UX design,
    frontend and backend development, DevOps and QA automation. Our company is built on networking expertise, and it is
    the very core of what we do every day for our global partners, working across all phases of the software development
    life cycle.
  </>,
  <>We are your strategic partner. And we will boost your technology-driven business.</>,
];

export const standardSectionProps = {
  title: 'Top-notch software development outsourcing for your business',
  classNames: {
    title: styles.aboutUsTitle,
    section: styles.standardSection,
    outerWrapper: styles.outerWrapper,
    innerWrapper: styles.innerWrapper,
  },
};

import React from 'react';
import cx from 'classnames';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ParagraphsWithRectangles } from '../paragraph-with-rectangles/ParagraphWithRectangles';

import * as styles from './section-with-rectangles.module.scss';

export const SectionWithRectangles = ({
  mainTitle,
  description,
  categoriesData,
  sectionId,
  differentWidthOfLast = false,
  classNames: { sectionCustomStyles } = {},
}) => {
  return (
    <StandardSection
      title={mainTitle}
      subtitle={description}
      classNames={{
        section: cx(styles.section, sectionCustomStyles),
        innerWrapper: styles.innerWrapper,
        title: styles.mainTitle,
        subtitle: styles.subtitle,
      }}
      id={sectionId}
    >
      {categoriesData.map((categoryData) => (
        <ParagraphsWithRectangles
          key={categoryData.categoryTitle}
          differentWidthOfLast={differentWidthOfLast}
          {...categoryData}
        />
      ))}
    </StandardSection>
  );
};

import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { SingleBoxDescriptive } from '@commons/single-box-descriptive/SingleBoxDescriptive';

import { benefitsData, standardSectionProps } from './payload';

import * as styles from './benefits.module.scss';

export const BenefitsOfOutsourcing = () => {
  return (
    <StandardSection {...standardSectionProps}>
      <div className={styles.benefitsContainer}>
        {benefitsData.map((benefit, index) => (
          <SingleBoxDescriptive
            className={styles.singleBoxStyles}
            key={index}
            title={benefit.name}
            alt={benefit.name}
            src={benefit.icon}
            textContent={benefit.description}
            classNames={{
              description: styles.boxTitle,
            }}
          />
        ))}
      </div>
    </StandardSection>
  );
};

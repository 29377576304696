import React from 'react';

import featuredImage from '@images/header-images/outsourcing-software-featured-image.jpg';

import * as styles from './payload.module.scss';

export const headerProps = {
  descriptionText: 'Entrust your software development project to experts',
  title: 'Software outsourcing services',
  showImageOnMobile: true,
  src: 'outsourcing-software-header-image.webp',
  srcSmall: 'codilime_golang_header_small.png',
  displayTopBanner: false,
  className: styles.header,
};

export const seoProps = {
  title: 'Best Software Development Outsourcing Company | CodiLime',
  description:
    'Software outsourcing services company backed by 11+ years of experience focusing on UX design, full stack development and networks engineering.',
  featuredImage: featuredImage,
};

export const customHowWeWorkProps = {
  customMainTitle: 'Models of software outsourcing services',
  customSubtitle: (
    <>
      At CodiLime, we want to offer our tech-savvy partners{' '}
      <span className={styles.boldText}>maximum freedom and flexibility</span>. As your outsourcing partner, we have
      several cooperation models and we are always ready to adjust to your needs.
    </>
  ),
  customAltText: 'partnership models in software outsourcing',
  customSectionStyles: styles.section,
};

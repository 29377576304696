import React from 'react';
import { Button } from '@commons/button/Button';

import cx from 'classnames';

import arrowImg from '@images/technology-stack/arrow.svg';

import * as styles from './single-rectangle.module.scss';
import { Link } from '@commons/link/Link';

export const SingleRectangle = ({ src, link = '', alt, textOnly = '' }) => {
  return (
    <>
      {link ? (
        <Link className={cx(styles.rectangleContainer, styles.activeRectangle)} href={link}>
          <div className={styles.onHover}>
            <Button label="Read more" className={styles.readMoreButton} />
          </div>
          {textOnly ? (
            <p className={styles.textInRectangle}>{textOnly}</p>
          ) : (
            <img loading="lazy" src={src} name={alt} alt={alt} className={styles.icon} />
          )}
          <img loading="lazy" src={arrowImg} alt="arrow" className={styles.arrow} />
        </Link>
      ) : (
        <div className={styles.rectangleContainer}>
          <img loading="lazy" src={src} name={alt} alt={alt} className={styles.icon} />
        </div>
      )}
    </>
  );
};

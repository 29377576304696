import React from 'react';

import * as styles from './our-services.module.scss';
import { Link } from '@commons/link/Link';

export const standardSectionProps = {
  id: 'our-services',
  title: 'Our software development outsourcing services',
  subtitle: (
    <>
      See how our dedicated software development teams, network engineers and R&D can help you reach your business
      goals.
    </>
  ),
  classNames: {
    title: styles.ourServicesTitle,
    subtitle: styles.ourServicesSubtitle,
    innerWrapper: styles.innerWrapper,
  },
};

export const ourServicesData = [
  {
    title: 'Network Software Engineering',
    services: [
      <Link href="/services/network-software-engineering/#nse-ui-ux-section" key="1-1">
        UX & UI
      </Link>,
      <Link href="/services/network-software-engineering/#nse-fullstack-dev-section" key="1-2">
        Full stack development
      </Link>,
      <Link href="/services/network-software-engineering/#nse-poc-mvp-section" key="1-3">
        PoC / MVP development
      </Link>,
      <Link href="/services/network-software-engineering/#nse-software-integration-section" key="2-4">
        Software integration
      </Link>,
      <Link href="/services/network-software-engineering/#nse-hardware-integration-section" key="2-5">
        Hardware integration
      </Link>,
      <Link href="/services/network-software-engineering/#nse-ci-cd-section" key="2-6">
        CI / CD
      </Link>,
      <Link href="/services/network-software-engineering/#nse-test-automation-section" key="2-7">
        Test automation
      </Link>,
    ],
  },
  {
    title: 'Network Professional Services',
    services: [
      <Link href="/services/network-professional-services/#nps-expert-consultancy" key="2-1">
        Expert consultancy
      </Link>,
      <Link href="/services/network-professional-services/#nps-network-automation" key="2-2">
        Network automation
      </Link>,
      <Link href="/services/network-professional-services/#nps-solutions-design" key="2-3">
        Solutions design
      </Link>,
      <Link href="/services/network-professional-services/#nps-solution-poc" key="2-4">
        PoC of designed solution
      </Link>,
      <Link href="/services/network-professional-services/#nse-solution-validation" key="2-5">
        Solution validation
      </Link>,
      <Link href="/services/network-professional-services/#nps-solutions-integration-and-deployment" key="2-6">
        Solution integration & deployment
      </Link>,
    ],
  },
  {
    title: 'R&D',
    services: [
      <Link href="/services/rd-services/#research" key="3-1">
        Research
      </Link>,
      <Link href="/services/rd-services/#proof-of-concept" key="3-2">
        Proof of Concept
      </Link>,
    ],
  },
];

import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { BoxWithButtonAndImage } from '@pages-impl/golang-development/common/BoxWithButtonAndImage';

import { standardSectionProps, knowUsBetterData } from '@pages-impl/software-outsourcing/know-better/payload';

import * as styles from './know-us-better.module.scss';

export const KnowUsBetter = () => {
  return (
    <StandardSection {...standardSectionProps}>
      <BoxWithButtonAndImage {...knowUsBetterData}>
        <span className={styles.buttonHeadline}>Know CodiLime better and see the About Us Page</span>
      </BoxWithButtonAndImage>
    </StandardSection>
  );
};

import React from 'react';

import {
  react,
  p4,
  openVSwitch,
  tungstenFabric,
  go,
  python,
  rust,
  dpdk,
  kubernetes,
} from '@images/logos-techstack-bigger';

import { paths } from '@src/constants/pathsEnum';

import * as styles from './our-tech-stack.module.scss';

export const standardSectionProps = {
  title: 'Technologies we work with',
  subtitle: (
    <>
      When working on complex software and network solutions you need a carefully selected tech stack combined with the
      skills of exceptional software engineers to ensure nothing less than code excellence.
    </>
  ),
  classNames: {
    section: styles.section,
    title: styles.ourTechStackTitle,
    subtitle: styles.ourTechStackSubtitle,
  },
};

export const technologyStackData = {
  sectionId: 'technology-stack',
  mainTitle: 'Technologies we work with',
  description:
    'When working on complex software and network solutions you need a carefully selected tech stack combined with the skills of exceptional software engineers to ensure nothing less than code excellence.',
  categoriesData: [
    {
      categoryTitle: '',
      technologiesData: [
        react,
        go,
        python,
        rust,
        p4,
        kubernetes,
        dpdk,
        tungstenFabric,
        openVSwitch,
        {
          link: paths.TECHNOLOGIES,
          textOnly: 'See the whole tech stack',
        },
      ],
    },
  ],
};

import React from 'react';

import { SectionWithRectangles } from './section-with-rectangles/SectionWithRectangles';

import { technologyStackData } from './payload';

import * as styles from './our-tech-stack.module.scss';

export const OurTechStack = () => {
  return (
    <SectionWithRectangles
      {...technologyStackData}
      differentWidthOfLast
      classNames={{ sectionCustomStyles: styles.section }}
    />
  );
};

import React from 'react';

import SEO from '@commons/SEO';
import Layout from '@src/layouts';
import { Header } from '@commons/header/Header';
import { TrustedBySection } from '@commons';
import {
  AboutUs,
  BenefitsOfOutsourcing,
  KnowUsBetter,
  //OurServices,
  OurTechStack,
  HowWeWork,
} from '@pages-impl/software-outsourcing';

import { headerProps, seoProps, customHowWeWorkProps } from '@pages-impl/software-outsourcing/payload';

import * as styles from './software-sourcing.module.scss';

export default function SoftwareOutsourcingServices(props) {
  const trustedByTitle = 'Our clients about us';

  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Ready to build your software product with us?"
      oldPage
      {...props}
    >
      <SEO {...seoProps} />
      <Header {...headerProps} />
      <AboutUs />
      <BenefitsOfOutsourcing />
      <HowWeWork {...customHowWeWorkProps} />
      <KnowUsBetter />
      {/*<OurServices /> //TODO uncomment when Paulina will create a new design and we rework it */}
      <OurTechStack />
      <TrustedBySection title={trustedByTitle} className={styles.trustedBySection} />
    </Layout>
  );
}

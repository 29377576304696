import * as styles from './know-us-better.module.scss';

import downloadImage from '@images/technology-stack/see-technology.svg';

import { paths } from '@src/constants/pathsEnum';

export const knowUsBetterData = {
  downloadImage: downloadImage,
  buttonTitle: 'Explore',
  buttonHref: paths.ABOUT,
  imageAlt: 'Get to know us better',
  classNames: {
    firstRow: styles.firstRow,
    descriptionContainer: styles.textWrapper,
    imageContainer: styles.imageContainer,
    buttonWithLink: styles.buttonWithLink,
  },
};

export const standardSectionProps = {
  id: 'know-us-better',
  classNames: {
    section: styles.section,
    innerWrapper: styles.innerWrapper,
  },
};

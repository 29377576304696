// extracted by mini-css-extract-plugin
export var aboutUsTitle = "about-us-module--about-us-title--3840d";
export var boldText = "about-us-module--bold-text--1c519";
export var button = "about-us-module--button--99193";
export var imageContainer = "about-us-module--image-container--18a0f";
export var innerWrapper = "about-us-module--inner-wrapper--fc4ff";
export var outerWrapper = "about-us-module--outer-wrapper--f6d06";
export var paragrapghContent = "about-us-module--paragrapgh-content--f0983";
export var paragraphsContainer = "about-us-module--paragraphs-container--a6d6b";
export var sectionContainer = "about-us-module--section-container--3bb75";
export var standardSection = "about-us-module--standard-section--bb12a";
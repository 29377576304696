import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { SingleBoxDescriptive } from '@commons/single-box-descriptive/SingleBoxDescriptive';

import { howWeWorkData } from './payload';

import cx from 'classnames';

import * as styles from './how-we-work.module.scss';

export const HowWeWork = ({ customMainTitle, customSubtitle, customSectionStyles, customAltText = 'partners' }) => {
  const { mainTitle, subtitle, imgOnLeft, tilesData } = howWeWorkData;
  return (
    <StandardSection
      title={customMainTitle || mainTitle}
      subtitle={customSubtitle || subtitle}
      classNames={{
        section: cx(styles.section, customSectionStyles),
        innerWrapper: styles.innerWrapper,
        title: styles.mainTitle,
        subtitle: styles.subtitle,
      }}
      id="how-we-work"
    >
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <img loading="lazy" src={imgOnLeft} alt={customAltText} />
        </div>
        <div className={styles.containerWithTiles}>
          {tilesData.map((titleData) => {
            return (
              <SingleBoxDescriptive
                key={titleData.title}
                {...titleData}
                className={styles.singleTile}
                classNames={{ description: styles.tileDescription }}
              />
            );
          })}
        </div>
      </div>
    </StandardSection>
  );
};

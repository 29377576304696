import React from 'react';

import { paragraphsData, standardSectionProps } from '@pages-impl/software-outsourcing/about-us/payload';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { Button } from '@commons/button/Button';

import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './about-us.module.scss';

export const AboutUs = () => {
  return (
    <StandardSection {...standardSectionProps}>
      <div className={styles.sectionContainer}>
        <div className={styles.paragraphsContainer}>
          {paragraphsData.map((pargrapgh, index) => (
            <p key={index} className={styles.paragrapghContent}>
              {pargrapgh}
            </p>
          ))}
          <Button className={styles.button} label="Let's talk!" href="#contact" />
        </div>
        <div className={styles.imageContainer}>
          <StaticImage src="./images/outsourcing-services.svg" alt="software outsourcing services" />
        </div>
      </div>
    </StandardSection>
  );
};

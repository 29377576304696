import React from 'react';

import imgOnLeft from '@images/technology-stack/how-we-work/partners.png';
import expertiseImg from '@images/technology-stack/how-we-work/expertise.svg';
import notebookImg from '@images/technology-stack/how-we-work/notebook.svg';
import cupImg from '@images/technology-stack/how-we-work/cup.svg';
import servicesImg from '@images/technology-stack/how-we-work/services.svg';

export const howWeWorkData = {
  mainTitle: 'How do we work with our tech-savvy partners?',
  subtitle:
    'Whether you need experts in a particular technology or are looking for a partner who will deliver a complete software product to your doorstep, we are here to help you. Check out how we can work together.',
  imgOnLeft: imgOnLeft,
  tilesData: [
    {
      title: 'Team augmentation',
      subtitle: (
        <>
          When you are already working on a solution and need tech specialists to{' '}
          <span className="bold-font">expand your team</span>.
        </>
      ),
      src: notebookImg,
    },
    {
      title: 'Tailored team',
      subtitle: (
        <>
          When you have a task to be done and want to outsource it to a{' '}
          <span className="bold-font">team of professionals</span>.
        </>
      ),
      src: cupImg,
    },
    {
      title: 'End-to-end services',
      subtitle: (
        <>
          When you want to focus on business and need a <span className="bold-font">technology partner</span> to turn
          your ideas into working solutions.
        </>
      ),
      src: servicesImg,
    },
    {
      title: 'Consulting & expertise',
      subtitle: (
        <>
          When you want to speed up and need <span className="bold-font">experts on a temporary basis</span> to foster
          your growth or transformation.
        </>
      ),
      src: expertiseImg,
    },
  ],
};

import React from 'react';
import cx from 'classnames';

import { SingleRectangle } from '../single-rectangle/SingleRectangle';

import * as styles from './paragraph-with-rectangles.module.scss';

export const ParagraphsWithRectangles = ({ categoryTitle, technologiesData, differentWidthOfLast = false }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{categoryTitle}</p>
      <div className={cx(styles.rectanglesContainer, { [styles.differentRectanglesContainer]: differentWidthOfLast })}>
        {technologiesData.map((technologyData) => (
          <SingleRectangle key={technologyData.alt} {...technologyData} />
        ))}
      </div>
    </div>
  );
};

import React from 'react';

import smartCostIcon from './images/smart-cost-approach.svg';
import fasterTimeIcon from './images/faster-time.svg';
import talentIcon from './images/talent-problem.svg';
import productSecurityIcon from './images/process-security.svg';
import processFlexibilityIcon from './images/process-flexibility.svg';

import * as styles from '@pages-impl/software-outsourcing/benefits/benefits.module.scss';

export const benefitsData = [
  {
    name: 'Smart cost approach',
    description: (
      <p className={styles.singleBoxDescriptiveText}>
        Recruiting and training new software developers is a process that{' '}
        <span className={styles.boldText}>raises the cost</span> of every software development project. Additionally,
        the logistics and infrastructure required to build a workplace for developers are often overlooked expenses that
        indirectly affect every project&apos;s budget. If we add the cost of constant skills development, then the risk
        of project overspend increases further. Finding a vendor that bears all these costs gives you the freedom to
        focus on what really matters - your business.
      </p>
    ),
    icon: smartCostIcon,
  },
  {
    name: 'Faster time to market',
    description: (
      <p className={styles.singleBoxDescriptiveText}>
        In a highly competitive market, introducing your innovation before the competition is essential. The demanding
        and tedious process of recruiting an in-house team for a new project is time-consuming and interrupts your
        progress. And this is just one issue of many that can slow you down. Especially if your business idea has to be
        validated fast to reduce potential future overspending, having a partner with dedicated development teams
        operating in the required technologies can help you{' '}
        <span className={styles.boldText}>speed up your time to market</span>.
      </p>
    ),
    icon: fasterTimeIcon,
  },
  {
    name: 'Solving the talent problem',
    description: (
      <p className={styles.singleBoxDescriptiveText}>
        According to industry data provided by the Bureau of Labor Statistics, by 2026,{' '}
        <span className={styles.boldText}>the shortage of engineers in the US will exceed 1.2M</span>. Every year, the
        US market advertises 200,000 positions for hard-to-replace software engineers. This lack of talent is a global
        trend that affects most technology-driven businesses. In the network industry, where the level of complexity is
        very high, partnering with a vendor with an adequate bank of skills can be an excellent way to solve talent
        shortage problems.
      </p>
    ),
    icon: talentIcon,
  },
  {
    name: 'Product security',
    description: (
      <p className={styles.singleBoxDescriptiveText}>
        When outsourcing to an external company, you want to know that your data and sensitive information are secure.
        At <span className={styles.boldText}>CodiLime, we follow ISO 27001 information security standards</span> to
        ensure that our partners&apos; data is safe with us.
      </p>
    ),
    icon: productSecurityIcon,
  },
  {
    name: 'Flexibility of the process',
    description: (
      <p className={styles.singleBoxDescriptiveText}>
        When partnering with an external software development company, you can choose which model you will work with and
        under what methodology you will develop the software. You can adjust these factors according to the project you
        are running and the in-house needs. It can be a brilliant opportunity to experiment with process and
        methodology.
      </p>
    ),
    icon: processFlexibilityIcon,
  },
];

export const standardSectionProps = {
  id: 'benefits-of-outsourcing',
  title: 'Benefits of outsourcing software development',
  subtitle:
    'Partnering with a software outsourcing company has numerous benefits that will positively impact your business. To name just a few:',
  classNames: {
    section: styles.section,
    title: styles.benefitsTitle,
    subtitle: styles.benefitsSubtitle,
    innerWrapper: styles.sectionInnerWrapper,
  },
};
